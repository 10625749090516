.works{
    /* padding-top: 100px; */
}

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  .custom-shape-divider-top-1709841156 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1709841156 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 62px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1709841156 .shape-fill {
    fill: #FFFFFF;
}