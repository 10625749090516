h1{
    padding-top: 100px;
}

.custom-shape-divider-top-1709842588 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1709842588 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 62px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1709842588 .shape-fill {
    fill: #030712;
}