
.intro-img img{
    max-width: 500px;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
@media (max-width: 767px){
    .intro-img img{
        position: relative;
        max-width: 100%;
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    .intro-message{
        position: absolute;
        z-index: 1;
        text-align: center;
    }
}
.tilt {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.tilt svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 62px;
    transform: rotateY(180deg);
}

.tilt .shape-fill {
    fill: #FFFFFF;
}

