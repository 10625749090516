.custom-shape-divider-top-1709843139 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1709843139 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 62px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1709843139 .shape-fill {
    fill: #FFFFFF;
}